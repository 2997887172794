<script>
import { Kalendar, NavigationDrawer } from "../../components/index";

export default {
  name: "Kegiatan",
  components: {
    NavigationDrawer,
    Kalendar,
  },
  data: () => ({
    drawer: null,
    auth: [],
    errors: "",
    dialog: "",
    time: "",
    menu2: "",
  }),
  methods: {},
};
</script>
