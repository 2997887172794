<script>
import { NavigationDrawer } from "../../components/index";
import axios from "axios";
import {
  indexPerson,
  showPerson,
  createPerson,
  updatePerson,
  deletePerson,
} from "../../services/index";

export default {
  components: {
    NavigationDrawer,
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    // menu1: false,
    row: null,
    drawer: null,
    rules: [(v) => !!v || "required"],
    headers: [
      { text: "No.", value: "no", sortable: false },
      { text: "Nama", value: "nama" },
      { text: "Gender", value: "gender" },
      { text: "Alamat", value: "alamat" },
      { text: "Tanggal Lahir", value: "tanggalLahir" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    users: [],
    editedIndex: -1,
    snackbar: false,
    snackbarText: "",
    passwordShow: false,
    ulangipassword: false,
    form: {
      nama: "",
      tanggalLahir: "",
      gender: "",
      alamat: "",
      password: "",
      ulangipassword: "",
      level: "",
      username: "",
    },
    nama: "",
    editItem: {},
    editId: "",
    count: 0,
    dialogCreate: false,
    dialogDelete: false,
    dialogEdit: false,
    name: "User",
  }),
  metaInfo() {
    return {
      title: "User management",
      // meta: [
      //     { name: 'description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline},
      //     { property: 'og:title', content: this.userData.name + ' - Epiloge'},
      //     { property: 'og:site_name', content: 'Epiloge'},
      //     { property: 'og:description', content: 'Connect and follow ' + this.userData.name + ' on Epiloge - ' + this.userData.tagline},
      //     {property: 'og:type', content: 'profile'},
      //     {property: 'og:url', content: 'https://epiloge.com/@' + this.userData.username},
      //     {property: 'og:image', content: this.aws_url + '/users/' + this.userData.profileurl + '-main.jpg' }
      // ]
    };
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    editUser(item) {
      this.dialogEdit = true;
      this.editItem = item;
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.editItem = item.idPerson;
    },
    loadUser() {
      axios
        .get(indexPerson)
        .then((result) => {
          this.count = result.data.count;
          const user = [];
          var nomor = 1;
          result.data.results.forEach((data) => {
            user.push({
              no: nomor++,
              idPerson: data.idPerson,
              nama: data.nama,
              gender: data.gender,
              alamat: data.alamat,
              tanggalLahir: data.tanggalLahir,
              created_at: data.created_at,
              updated_at: data.updated_at,
            });
          });
          this.users = user;
          this.colorSnackbar = "green";
          this.snackbar = true;
          this.snackbarText = result.data.message;
          console.log(this.users);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadDetailUser(id) {
      axios
        .get(`${showPerson}/${id}`)
        .then((result) => {
          console.log(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    save() {
      if (this.form.password == this.form.ulangipassword) {
        axios
          .post(`${createPerson}`, this.form)
          .then((res) => {
            if (res.data.status == 200) {
              this.loadUser();
              this.dialogCreate = false;
            }
          })
          .catch((err) => {
            // handle error
            this.showSnackbar = true;
            this.textSnackbar = err;
          });
      } else {
        this.showSnackbar = true;
        this.textSnackbar = "Password anda tidak sama";
      }
    },

    deleteUser() {
      try {
        axios.delete(deletePerson + this.editId).then((results) => {
          switch (results.data.status) {
            case 200:
              this.loadUser();
              this.dialogDelete = false;
              break;
            case 404:
              this.loadUser();
              this.dialogDelete = false;
              this.colorSnackbar = "green";
              this.snackbar = true;
              this.snackbarText = results.data.message;
              break;
            case 500:
              this.colorSnackbar = "green";
              this.loadUser();
              this.dialogDelete = false;
              this.snackbar = true;
              this.snackbarText = results.data.message;
              break;
            default:
              this.colorSnackbar = "red";
              this.loadUser();
              this.dialogDelete = false;
              this.snackbar = true;
              this.snackbarText = results.data;
              break;
          }
        });
      } catch (error) {
        this.colorSnackbar = "red";
        this.dialogDelete = false;
        this.snackbar = true;
        this.snackbarText = error;
      }
    },

    updateUser(value) {
      
      try {
        axios
          .put(updatePerson + `${value.idPerson}`, {
            nama: value.nama,
            tanggalLahir: value.tanggalLahir,
            gender: value.gender,
            alamat: value.alamat,
          })
          .then((results) => {
            switch (results.data.status) {
              case 200:
                this.colorSnackbar = "green";
                this.loadUser();
                this.dialogEdit = false;
                break;
              case 404:
                this.colorSnackbar = "green";
                this.loadUser();
                this.dialogEdit = false;
                this.snackbar = true;
                this.snackbarText = results.data.message;
                break;
              case 500:
                this.colorSnackbar = "green";
                this.loadUser();
                this.dialogEdit = false;
                this.snackbar = true;
                this.snackbarText = results.data.message;
                break;
              default:
                this.colorSnackbar = "red";
                this.loadUser();
                this.dialogEdit = false;
                this.snackbar = true;
                this.snackbarText = results.data;
                break;
            }
          });
      } catch (error) {
        this.colorSnackbar = "red";
        this.dialogEdit = false;
        this.snackbar = true;
        this.snackbarText = error;
      }
    },
  },
};
</script>
