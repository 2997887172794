<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  TableCell,
  TableHeader,
  TodoList,
  TodoItem,
  TableRow,
} from "tiptap-vuetify";
import { TrailingNode } from "tiptap-extensions";
// or your own extension
import { required, regex } from "vee-validate/dist/rules";
import {
  indexEvent,
  showEvent,
  createEvent,
  updateEvent,
  baseUrl,
  deleteEvent,
  logUrl,
  category,
} from "../../services/index";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import Chat from "../Chat/Chat.vue";
import axios from "axios";
import ApiSservice from "../../services/api.js";
const service = new ApiSservice();

setInteractionMode("eager");

// extend('digits', {
//   ...digits,
//   message: '{_field_} needs to be {length} digits. ({_value_})',
// })

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

// extend('max', {
//   ...max,
//   message: '{_field_} may not be greater than {length} characters',
// })

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    TiptapVuetify,
    chats: Chat,
  },
  data: () => ({
    focus: "",
    type: "month",
    baseUrl: baseUrl,
    loading: false,
    content: ``,
    nativeExtensions: [
      new TrailingNode({
        node: "paragraph",
        notAfter: ["paragraph"],
      }),
    ],
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
      TableCell,
      TableHeader,
      TodoList,
      TableRow,
      [
        TodoItem,
        {
          options: {
            nested: true,
          },
        },
      ],
    ],
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },

    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    detailsEvent: false,
    createOpen: "",
    dialogChat: false,
    events: [],
    tags: [],
    dialog: "",
    dialogdelete: false,
    dialogUpdate: false,
    kegiatan: {
      listGambar: [],
      tanggal: "",
      status: "",
      listHapus: [],
      ListTags: [],
    },
    warna: {
      approve: "#dc3545",
      progress: "#ffc107",
      done: "#007bff",
      draft: "#2ECC71 ",
    },
    tanggalKegiatan: "",
    waktuKegiatan: "",
    menu: "",
    menu2: "",
    errors: "",
    colorSnackbar: "",
    snackbar: false,
    snackbarText: "",
    items: ["Approve", "Done", "Progress"],
    form: {
      tanggal: "",
      title: "",
      description: "",
      gambar: "",
      video: "",
    },
    category: [],
    // formSave: {
    //   tanggal: "",
    //   waktu:"",
    //   title: "",
    //   description: "",
    //   status: "",
    //   gambar: "",
    //   video: "",
    // },
    listTags: [],
    listGambar: [],
    titleKegiatan: "",
    editKegiatan: false,
    auth: {},
    date: "",
    time: "",
    allowedStep: true,
    listHapus: [],
    loading: false,
    loadingChat: false,
    listChats: [],
    search: null,
    ListTags: [],
    modal2: "",
    tanggalPilih: "",
  }),

  mounted() {
    this.kegiatan.listHapus = [];
    //this.$refs.calendar.checkChange();
    this.loadEvent();
    this.loadCategory();
    this.auth = JSON.parse(localStorage.getItem("AUTH"));
  },

  methods: {
    checkChange() {},
    closeChat: function (index) {
      this.dialogChat = false;
      this.listChats = [];
      this.loadEvent();
    },
    openChat(selected) {
      this.loadingChat = true;
      this.listChats = [];
      service.serviceGet(selected.id).then((res) => {
        switch (res.status) {
          case 404:
            this.snackbarText = res.message;
            this.loadingChat = false;
            break;
          default:
            this.listChats = res.results;
            this.loadingChat = false;
            this.dialogChat = true;
        }
      });
    },
    removeTags(item) {
      this.kegiatan.ListTags.splice(this.kegiatan.ListTags.indexOf(item), 1);
      this.kegiatan.ListTags = [...this.kegiatan.ListTags];
    },
    removeImage(item) {
      this.kegiatan.listHapus.push({
        ext: "",
        img: item.imgRaw,
        idImage: item.idImage,
      });
      this.listGambar.splice(this.listGambar.indexOf(item), 1);
      this.listGambar = [...this.listGambar];
    },
    loadEvent() {
      axios
        .get(indexEvent)
        .then((result) => {
          switch (result.data.status) {
            case 200:
              const events = [];
              result.data.results.forEach((data) => {
                const allDay = this.rnd(0, 3) === 0;
                events.push({
                  id: data.idKegiatan,
                  name: data.kategori,
                  start: data.tanggal,
                  end: data.tanggal,
                  showDate: data.tanggalFormat,
                  desc: data.deskripsi,
                  kegiatan: data,
                  status: data.status,
                  gambar:
                    data.listGambar.length > 0 ? data.listGambar[0].img : null,
                  video: data.video,
                  color:
                    data.status === "Approve"
                      ? this.warna.approve
                      : data.status === "Progress"
                      ? this.warna.progress
                      : data.status === "Draft"
                      ? this.warna.draft
                      : this.warna.done,
                  timed: !allDay,
                });
              });
              this.events = events;
              break;
            case 404:
              this.snackbar = true;
              this.snackbarText = result.data.message;
              break;
            default:
              this.snackbar = true;
              this.snackbarText = result.data.message;
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    loadCategory() {
      try {
        axios
          .get(category)
          .then((result) => {
            switch (result.data.status) {
              case 200:
                const category = [];
                result.data.results.forEach((data) => {
                  category.push({
                    idCategory: data.idCategory,
                    category: data.category,
                  });
                });
                this.category = category;
                break;
              case 404:
                // this.snackbar = true;
                // this.snackbarText = result.data.message;
                break;
              default:
                // this.snackbar = true;
                // this.snackbarText = result.data.message;
                break;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    },
    setImage: function (file) {
      this.hasImage = true;
      this.image = file;
    },
    async handleFileUpload(e) {
      e.target.files.forEach((item, key) => {
        this.createBase64Image(item, key);
      }); // get first file
    },
    createBase64Image(fileObject, key) {
      const reader = new FileReader();
      let ext = fileObject.type.split("/");
      reader.readAsDataURL(fileObject);
      return (reader.onload = (e) => {
        this.listGambar.push({
          stats: "baru",
          ext: ext[1],
          img: e.target.result,
          id: key,
        });
      });
    },
    openfile() {
      $("#imgupload").trigger("click");
      // let compressor = this.$refs.compressor.$el;
      // compressor.click();
    },
    getFiles(obj) {
      // this.img = obj.compressed.blob
      // this.original = obj.original;
      // this.compressed = obj.compressed;
      // console.log(obj);
      // this.listGambar.push({ ext: "", img: obj.compressed.blob, id: "" });
    },
    share() {},
    approve(status) {
      this.loading = true;
      axios
        .put(createEvent + "/" + this.selectedEvent.id, { status: status })
        .then((result) => {
          this.loading = false;
          switch (result.data.status) {
            case 200:
              this.colorSnackbar = "green";
              this.loadEvent();
              this.editKegiatan = false;
              this.kegiatan.listHapus = [];
              this.selectedOpen = false;
              this.snackbar = true;

              this.snackbarText = result.data.message;
              break;
            case 400:
              this.colorSnackbar = "red";
              this.createOpen = false;
              this.snackbar = true;
              this.snackbarText = result.data.message;
              break;
            default:
              this.colorSnackbar = "red";
              this.createOpen = false;
              this.snackbar = true;
              this.snackbarText = result.data.message;
              break;
          }
        })
        .catch((error) => {
          this.colorSnackbar = "red";
          this.createOpen = false;
          this.snackbar = true;
          this.snackbarText = error;
        });
    },
    submitForm() {
      this.kegiatan.tanggal = this.tanggalKegiatan + " " + this.waktuKegiatan;
      this.kegiatan.listGambar = this.listGambar;
      this.kegiatan.status =
        this.auth.level == "admin" ? "Approve" : "Draft";
      if (this.kegiatan.video == undefined) {
        this.kegiatan.video = "";
      }
      if (this.kegiatan.deskripsi == undefined) {
        this.kegiatan.deskripsi = "";
      }
      if (this.kegiatan.ListTags == undefined) {
        this.kegiatan.ListTags = [];
      }
      if (this.kegiatan.listGambar == undefined) {
        this.kegiatan.listGambar = [];
      }
      if (!this.editKegiatan) {
        this.loading = true;
        axios
          .post(createEvent, this.kegiatan)
          .then((result) => {
            this.loading = false;
            switch (result.data.status) {
              case 200:
                this.colorSnackbar = "green";
                this.loadEvent();
                this.createOpen = false;
                this.editKegiatan = false;
                this.kegiatan = {};
                this.kegiatan.listHapus = [];
                this.snackbar = true;
                this.snackbarText = result.data.message;
                break;
              case 400:
                this.colorSnackbar = "red";
                this.createOpen = false;
                this.snackbar = true;
                this.snackbarText = result.data.message;
                break;
              default:
                this.colorSnackbar = "red";
                this.createOpen = false;
                this.snackbar = true;
                this.snackbarText = result.data.message;
                break;
            }
          })
          .catch((error) => {
            this.colorSnackbar = "red";
            this.createOpen = false;
            this.snackbar = true;
            this.snackbarText = error;
          });
      } else {
        this.loading = true;
        axios
          .put(createEvent + "/update/" + this.selectedEvent.id, this.kegiatan)
          .then((result) => {
            this.loading = false;
            switch (result.data.status) {
              case 200:
                this.colorSnackbar = "green";
                this.loadEvent();
                this.editKegiatan = false;
                this.kegiatan.listHapus = [];
                this.createOpen = false;
                this.snackbar = true;
                this.snackbarText = result.data.message;
                break;
              case 400:
                this.colorSnackbar = "red";
                this.createOpen = false;
                this.snackbar = true;
                this.snackbarText = result.data.message;
                break;
              default:
                this.colorSnackbar = "red";
                this.createOpen = false;
                this.snackbar = true;
                this.snackbarText = result.data.message;
                break;
            }
          })
          .catch((error) => {
            this.colorSnackbar = "red";
            this.createOpen = false;
            this.snackbar = true;
            this.snackbarText = error;
          });
      }
    },

    updateShow(item) {
      this.titleKegiatan = "Update Kegiatan";
      this.createOpen = true;
      this.kegiatan = item.kegiatan;
      this.waktuKegiatan = item.kegiatan.waktuKegiatan;
      this.kegiatan.listHapus = [];
      this.editKegiatan = true;
    },

    createEvent({ date }) {
      this.kegiatan = {};
      this.kegiatan.listHapus = [];
      const current = new Date();
      this.tanggalKegiatan = date;
      this.editKegiatan = false;
      this.createOpen = true;
      // this.kegiatan.tanggal = tanggal.toString();
      // console.log(this.date);

      this.titleKegiatan = "Kegiatan Baru";
      this.selectedEvent.color = "#dc3545";
    },
    updaterEvent(evetnID) {},

    deleteShow() {
      this.dialogdelete = true;
    },

    deleteEvent(eventId) {
      this.loading = true;
      axios
        .delete(deleteEvent + `${eventId}`)
        .then((result) => {
          this.loading = false;
          switch (result.data.status) {
            case 200:
              this.colorSnackbar = "green";
              this.dialogdelete = false;
              this.loadEvent();
              this.snackbar = true;
              this.snackbarText = result.data.message;
              break;
            case 404:
              this.colorSnackbar = "red";
              this.dialogdelete = false;
              this.snackbar = true;
              this.snackbarText = result.data.message;
              break;
            default:
              this.colorSnackbar = "red";
              this.dialogdelete = false;
              this.snackbar = true;
              this.snackbarText = result.data.message;
              break;
          }
        })
        .catch((err) => {
          this.colorSnackbar = "red";
          this.loadEvent();
          this.dialogdelete = false;
          this.snackbar = true;
          this.snackbarText = `${err}`;
        });
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    showEvent({ nativeEvent, event }) {
      // const open = () => {
      //   this.selectedEvent = event;
      //   this.selectedElement = nativeEvent.target;
      //   requestAnimationFrame(() =>
      //     requestAnimationFrame(() => (this.selectedOpen = true))
      //   );
      // };
      // if (this.selectedOpen) {
      //   this.selectedOpen = false;
      //   requestAnimationFrame(() => requestAnimationFrame(() => open()));
      // } else {
      //   open();
      // }
      // nativeEvent.stopPropagation();
    },

    showDetailEvent({ nativeEvent, event }) {
      this.selectedEvent = {};
      this.titleKegiatan = "Update kegiatan";
      this.ListTags = event.kegiatan.ListTags;
      this.tanggalKegiatan = event.kegiatan.tanggal;
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();

      // if (this.updateOpen) {
      //   this.updateOpen = false;
      //   requestAnimationFrame(() => requestAnimationFrame(() => open()));
      // } else {
      //   open();
      // }

      // nativeEvent.stopPropagation();
    },
    updateRange() {
      // const events = [];
      // const min = new Date(`${start.date}T00:00:00`);
      // const max = new Date(`${end.date}T23:59:59`);
      // //const days = (max.getTime() - min.getTime()) / 86400000;
      // //const eventCount = this.rnd(days, days + 20);
      // console.log(min);
      // console.log(max);
      // // console.log(days);
      // // console.log(eventCount);
      // for (let i = 0; i < 3; i++) {
      //   const allDay = this.rnd(0, 3) === 0;
      //   const firstTimestamp = this.rnd(min.getTime(), max.getTime());
      //   const first = new Date(firstTimestamp - (firstTimestamp % 900000));
      //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
      //   const second = new Date(first.getTime() + secondTimestamp);
      //   events.push({
      //     name: "contoh " +i,
      //     start: '2021-11-19 13:31:09',
      //     end: second,
      //     color: this.colors[this.rnd(0, this.colors.length - 1)],
      //     timed: !allDay,
      //   });
      // }
      // this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>