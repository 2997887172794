<script>
export default {
  data() {
    return {
      drawer: false,
      loading4: false,
      auth: [],
      //  this line route for admin
      items: [
        {
          title: "Kegiatan",
          icon: "mdi-calendar-outline",
          name: "Kegiatan",
          path: "/kegiatan",
        },
        {
          title: "User",
          icon: "mdi-account-tie",
          name: "User",
          path: "/user",
        },

        // { title: "Web", icon: "mdi-web", name: "Web", path: "/web" },
        {
          title: "Team",
          icon: "mdi-account-group-outline",
          name: "Team",
          path: "/team",
        },
        {
          title: "Category",
          icon: "mdi-menu",
          name: "Category",
          path: "/category",
        },
      ],
      // this line route user
      itemsPengguna: [
        {
          title: "Kegiatan",
          icon: "mdi-calendar-outline",
          name: "Kegiatan",
          path: "/kegiatan",
        },

        // {
        //   title: "Pengaturan",
        //   icon: "mdi-cogs",
        //   name: "Pengaturan",
        //   path: "/settings",
        // },
      ],
      mini: true,
    };
  },
  created() {
    var vm = this;
    this.auth = JSON.parse(localStorage.getItem("AUTH"));
    if (!this.auth && this.auth === null) {
      vm.$router.push({ path: "/" });
    }
  },
  methods: {
    logout() {
      var vm = this;
      localStorage.removeItem("AUTH");
      // localStorage.removeItem('TOKEN')
      vm.$router.push({ path: "/" });
    },
  },
};
</script>