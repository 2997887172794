import axios from "axios";
import { baseUrl } from ".";
export default class ApiSservice {
    constructor() {
    }
    serviceGet(id) {
        return axios.get(baseUrl + '/diskusi/show/' + id).then((result) => {
            return result.data;
        });
    }

    ambilpesan(id) {
        return axios.get(baseUrl + '/diskusi/ambil/' + id).then((result) => {
            return result.data;
        });
    }

    saveMessage(message, id) {
        return axios.put(baseUrl + '/diskusi/update/' + id, message).then((result) => {
            return result.data;
        });
    }

}
