<script>
import { NavigationDrawer } from "../../components/index";
import axios from "axios";
import {
  indexTeam,
  showTeam,
  createTeam,
  updateTeam,
  deleteTeam,
  indexPerson,
  memberUrl,
} from "../../services/index";

import { green, red, yellow, blue } from "../../assets/index";

export default {
  name: "Team",
  components: {
    NavigationDrawer,
  },
  data: () => ({
    modelMember: null,
    modelTeam: null,
    dialog: false,
    selectedTeam: null,
    dialogMember: false,
    dialogRemove: false,
    dialogDelete: false,
    // editedIndex: -1,
    chip: false,
    drawer: null,
    rules: [(v) => !!v || "required"],
    team: [],
    users: [],
    // listMember: [],
    createOpen: "",
    colorSnackbar: "",
    snackbar: "",
    snackbarText: "",
    idMember: null,
    nama: "",
  }),
  // computed: {
  //     formTitle () {
  //       return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
  //     },
  //   },
  mounted() {
    this.loadTeam();
    this.loadUser();
  },

  methods: {
    // ----- Start load data team -----
    loadTeam() {
      try {
        axios
          .get(indexTeam)
          .then((result) => {
            switch (result.data.status) {
              case 200:
                this.team = result.data.data;
                this.colorSnackbar = green;
                this.snackbar = true;
                this.snackbarText = result.data.message;
                break;
              case 404:
                this.colorSnackbar = red;
                this.snackbar = true;
                this.snackbarText = result.data.message;
                break;
              case 500:
                this.colorSnackbar = red;
                this.snackbar = true;
                this.snackbarText = result.data.message;
                break;
              default:
                this.colorSnackbar = red;
                this.snackbar = true;
                this.snackbarText = result.data.message;
            }
          })
          .catch((err) => {
            this.colorSnackbar = red;
            this.snackbar = true;
            this.snackbarText = result.data.message;
          });
      } catch (error) {
        this.colorSnackbar = red;
        this.snackbar = true;
        this.snackbarText = error;
      }
    },
    // ----- End load data team -----

    // ----- Start load data user in team -----
    loadUser() {
      try {
        axios
          .get(indexPerson)
          .then((result) => {
            switch (result.data.status) {
              case 200:
                this.users = result.data.results;
                this.colorSnackbar = green;
                this.snackbar = true;
                this.snackbarText = result.data.message;
                break;
              case 404:
                this.colorSnackbar = red;
                this.snackbar = true;
                this.snackbarText = result.data.message;
                break;
              case 500:
                this.colorSnackbar = red;
                this.snackbar = true;
                this.snackbarText = result.data.message;
                break;
              default:
                this.colorSnackbar = red;
                this.snackbar = true;
                this.snackbarText = result.data.message;
            }
          })
          .catch((error) => {
            this.colorSnackbar = red;
            this.snackbar = true;
            this.snackbarText = error;
          });
      } catch (error) {
        this.colorSnackbar = red;
        this.snackbar = true;
        this.snackbarText = error;
      }
    },
    // ----- End load data user in team -----

    // ----- Start create team -----
    buatTeam() {
      try {
        if (this.modelTeam == null) {
          // ----- Save team without users
          axios
            .post(createTeam, {
              namaTeam: this.nama,
            })
            .then((result) => {
              switch (result.data.status) {
                case 200:
                  this.colorSnackbar = "green";
                  this.loadTeam();
                  this.dialog = false;
                  this.snackbar = true;
                  this.snackbarText = result.data.message;
                  this.nama = null;
                  this.modelTeam == null;
                  break;
                case 400:
                  this.colorSnackbar = "red";
                  this.loadTeam();
                  this.dialog = false;
                  this.snackbar = true;
                  this.snackbarText = result.data.message;
                  break;
                default:
                  this.colorSnackbar = "red";
                  this.loadTeam();
                  this.dialog = false;
                  this.snackbar = true;
                  this.snackbarText = result.data.message;
                  break;
              }
            })
            .catch((error) => {
              this.colorSnackbar = "red";
              this.loadTeam();
              this.dialog = false;
              this.snackbar = true;
              this.snackbarText = error;
            });
        } else if (this.modelTeam != null) {
          // ----- Save team with users
          let listMember = [];
          this.modelTeam.forEach((element) => {
            listMember.push(element.idPerson);
          });
          axios
            .post(createTeam, {
              namaTeam: this.nama,
              member: listMember,
            })
            .then((result) => {
              switch (result.data.status) {
                case 200:
                  this.colorSnackbar = "green";
                  this.loadTeam();
                  this.dialog = false;
                  this.snackbar = true;
                  this.snackbarText = result.data.message;
                  this.nama = null;
                  this.modelTeam = null;
                  this.listMember == null;
                  break;
                case 400:
                  this.colorSnackbar = "red";
                  this.loadTeam();
                  this.dialog = false;
                  this.snackbar = true;
                  this.snackbarText = result.data.message;
                  break;
                default:
                  this.colorSnackbar = "red";
                  this.loadTeam();
                  this.dialog = false;
                  this.snackbar = true;
                  this.snackbarText = result.data.message;
                  break;
              }
            })
            .catch((error) => {
              this.colorSnackbar = "red";
              this.loadTeam();
              this.dialog = false;
              this.snackbar = true;
              this.snackbarText = error;
            });
        }
      } catch (error) {
        this.colorSnackbar = "red";
        this.dialog = false;
        this.snackbar = true;
        this.snackbarText = error;
      }
    },
    // ----- End buat team -----

    // ----- Start delete team -----
    deleteTeam(value) {
      try {
        let listMember = [];
        value.member.forEach((element) => {
          listMember.push(element.idMember);
        });

        axios
          .delete(deleteTeam + value.idTeam, {
            data: {
              member: listMember,
            },
          })
          .then((results) => {
            switch (results.data.status) {
              case 200:
                this.colorSnackbar = green;
                this.loadTeam();
                this.loadUser();
                this.dialogDelete = false;
                this.snackbar = true;
                this.snackbarText = results.data.message;
                break;
              case 404:
                this.colorSnackbar = red;
                this.dialogDelete = false;
                this.snackbar = true;
                this.snackbarText = results.data.message;
                break;
              case 500:
                this.colorSnackbar = green;
                this.loadTeam();
                this.loadUser();
                this.dialogDelete = false;
                this.snackbar = true;
                this.snackbarText = results.data.message;
                break;
              default:
                this.colorSnackbar = red;
                this.loadTeam();
                this.loadUser();
                this.dialogDelete = false;
                this.snackbar = true;
                this.snackbarText = results.data.message;
                break;
            }
          });
      } catch (error) {
        this.colorSnackbar = red;
        this.loadTeam();
        this.loadUser();
        this.dialogDelete = false;
        this.snackbar = true;
        this.snackbarText = error;
      }
    },

    // ----- End delete team -----
    openMember(team) {
      this.selectedTeam = team.idTeam;
      this.dialogMember = true;
    },

    // ----- Start create member in team -----
    saveAnggota() {
      let listMember = [];
      this.modelMember.forEach((element) => {
        listMember.push(element.idPerson);
      });
      try {
        axios
          .post(memberUrl, {
            idTeam: this.selectedTeam,
            member: listMember,
          })
          .then((result) => {
            switch (result.data.status) {
              case 200:
                this.colorSnackbar = green;
                this.loadTeam();
                this.loadUser();
                this.nama = null;
                this.modelMember = null;
                this.dialogMember = false;
                this.snackbar = true;
                this.snackbarText = result.data.message;
                break;
              case 400:
                this.colorSnackbar = red;
                this.loadTeam();
                this.loadUser();
                this.dialogMember = false;
                this.snackbar = true;
                this.snackbarText = result.data.message;
                break;
              default:
                this.colorSnackbar = red;
                this.loadTeam();
                this.loadUser();
                this.dialogMember = false;
                this.snackbar = true;
                this.snackbarText = result.data.message;
                break;
            }
          })
          .catch((error) => {
            this.colorSnackbar = red;
            this.loadTeam();
            this.loadUser();
            this.snackbar = true;
            this.snackbarText = error;
          });
      } catch (error) {
        this.colorSnackbar = red;
        this.snackbar = true;
        this.snackbarText = error;
      }
    },
    // ----- End create member in team -----

    // ----- Start delete member in team -----
    deleteAnggota() {
      try {
        console.log(this.idMember);
        axios.delete(memberUrl + `/delete/${this.idMember}`).then((results) => {
          console.log(results.data);
          switch (results.data.status) {
            case 200:
              this.colorSnackbar = green;
              this.loadTeam();
              this.loadUser();
              this.dialogRemove = false;
              this.snackbar = true;
              this.snackbarText = results.data.message;
              break;
            case 404:
              this.colorSnackbar = red;
              this.loadTeam();
              this.loadUser();
              this.dialogRemove = false;
              this.snackbar = true;
              this.snackbarText = results.data.message;
              break;
            case 500:
              this.colorSnackbar = green;
              this.loadTeam();
              this.loadUser();
              this.dialogRemove = false;
              this.snackbar = true;
              this.snackbarText = results.data.message;
              break;
            default:
              this.colorSnackbar = red;
              this.loadTeam();
              this.loadUser();
              this.dialogRemove = false;
              this.snackbar = true;
              this.snackbarText = results.data.message;
              break;
          }
        });
      } catch (error) {
        this.colorSnackbar = red;
        this.dialogRemove = false;
        this.snackbar = true;
        this.snackbarText = error;
      }
    },
    // ----- end delete member in team -----
  },
};
</script>
