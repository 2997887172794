<script>
import axios from "axios";
import { logUrl } from "../../services/index";
export default {
  name: "Login",
  data: () => ({
    loading: false,
    snackbar: false,
    snackbarText: "",
    colorSnackbar: "",
    passwordShow: false,
    username: "",
    usernameRules: [(v) => !!v || "Username is required"],
    password: "",
    auth: [],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 8) || "Password must be 8 character",
    ],
  }),
  created() {
    var vm = this;
    this.auth = JSON.parse(localStorage.getItem("AUTH"));
    if (this.auth && this.auth !== null) {
      vm.$router.push({ path: "/kegiatan" });
    }
  },
  methods: {
    submitHandler() {
      if (this.$refs.form.validate()) {
        axios
          .post(logUrl, {
            username: this.username,
            password: this.password,
          })
          .then((response) => {
            if (response.data.status === 200) {
              this.colorSnackbar = "green";
              this.loading = false;
              this.snackbar = true;
              this.snackbarText = "Login berhasil";
              var vm = this;
              localStorage.setItem("AUTH",  JSON.stringify(response.data.data));
              setTimeout(function () {
                vm.$router.push({ path: "/kegiatan" });
              }, 3000);
              //  write code go to other page ex -> Home page or Dashboard Page
            } else {
              this.colorSnackbar = "red";
              this.snackbar = true;
              this.snackbarText = "Password / username salah";
            }
          })
          .catch((err) => {
            this.colorSnackbar = "red";
            this.snackbar = true;
            this.snackbarText = err;
          });
      }
    },
  },
};
</script>