<script>
import { NavigationDrawer } from "../../components/index";

export default {
  name: "Web",
  components: {
    NavigationDrawer,
  },
  data: () => ({ drawer: null }),
};
</script>