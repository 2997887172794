<script>
import { NavigationDrawer } from "../../components/index";
import axios from "axios";
import { category } from "../../services/index";
export default {
  components: {
    NavigationDrawer,
  },
  data: () => ({
    headers: [
      { text: "No.", value: "no", sortable: false },
      { text: "Category", value: "category", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    lengthCategories: null,
    categories: [],
    form: {
      category: "",
      ListTags: [],
    },
    dialogDelete: false,
    idCategory: "",
    snackbar: false,
    snackbarText: "",
    titleForm: "",
    dialog: false,
    checkProcess: false,
  }),

  mounted() {
    this.loadCategory();
  },

  methods: {
    loadCategory() {
      try {
        axios
          .get(category)
          .then((result) => {
            switch (result.data.status) {
              case 200:
                const category = [];
                let nomor = 1;
                result.data.results.forEach((data) => {
                  category.push({
                    no: nomor++,
                    idCategory: data.idCategory,
                    category: data.category,
                    created_at: data.created_at,
                    updated_at: data.updated_at,
                  });
                });
                this.categories = category;
                this.lengthCategories = category.length;
                break;
              default:
                break;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        this.colorSnackbar = "red";
        this.snackbar = true;
        this.snackbarText = error;
      }
    },

    showDialog(item) {
      if (item == null) {
        this.dialog = true;
        this.titleForm = "Tambah Category";
        this.checkProcess = false;
      } else if (item != null) {
        this.dialog = true;
        this.titleForm = "Edit Category";
        this.idCategory = item.idCategory;
        this.form = item;
        this.checkProcess = true;
      }
    },

    processForm() {
      this.checkProcess == false ? this.postCategory() : this.editCategory();
    },

    postCategory() {
      try {
        axios.post(category, this.form).then((response) => {
          switch (response.data.status) {
            case 200:
              this.colorSnackbar = "green";
              this.loadCategory();
              this.form.category = null;
              this.dialog = false;
              this.snackbar = true;
              break;
            default:
              this.colorSnackbar = "red";
              this.loadCategory();
              this.form.category = null;
              this.dialog = false;
              this.snackbar = true;
              this.snackbarText = results.data.message;
              break;
          }
        });
      } catch (error) {
        this.colorSnackbar = "red";
        this.loadCategory();
        this.dialog = false;
        this.snackbar = true;
        this.snackbarText = error;
      }
    },

    editCategory() {
      try {
        axios
          .put(category + `/update/${this.idCategory}`, this.form)
          .then((response) => {
            switch (response.data.status) {
              case 200:
                this.colorSnackbar = "green";
                this.loadCategory();
                this.form.category = null;
                this.dialog = false;
                this.snackbar = true;
                break;

              default:
                this.colorSnackbar = "red";
                this.loadCategory();
                this.form.category = null;
                this.dialog = false;
                this.snackbar = true;
                this.snackbarText = results.data.message;
                break;
            }
          })
          .catch((error) => {
            this.colorSnackbar = "red";
            this.loadCategory();
            this.dialog = false;
            this.snackbar = true;
            this.snackbarText = error;
          });
      } catch (error) {
        this.colorSnackbar = "red";
        this.loadCategory();
        this.dialog = false;
        this.snackbar = true;
        this.snackbarText = error;
      }
    },

    dialogCategory(item) {
      this.dialogDelete = true;
      this.idCategory = item.idCategory;
    },

    deleteCategory() {
      try {
        axios
          .delete(category + `/delete/${this.idCategory}`)
          .then((results) => {
            switch (results.data.status) {
              case 200:
                this.colorSnackbar = "green";
                this.loadCategory();
                this.dialogDelete = false;
                this.snackbar = true;
                break;
              default:
                this.colorSnackbar = "red";
                this.loadCategory();
                this.dialogDelete = false;
                this.snackbar = true;
                this.snackbarText = results.data.message;
                break;
            }
          });
      } catch (error) {
        this.colorSnackbar = "red";
        this.loadCategory();
        this.dialogCreate = false;
        this.snackbar = true;
        this.snackbarText = error;
      }
    },
  },
};
</script>